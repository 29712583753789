.excel-page-main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
}

.excel-page-content {
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: fit-content;
}


.excel-page-header {
    margin-right: auto;
}

.excel-page-header .header-text-1 {
    font-size: calc(2.2em + 0.4vw);
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
}

.excel-page-header .header-text-2 {
    font-size: calc(1em + 0.4vw);
    text-align: left;

    margin: 0;
}


.react-grid-container {
    max-height: 65vh;
    max-width: 100%;
    overflow: auto;
    border: 1px solid #E7E7E7;
    border-radius: 15px;
}


.react-grid-container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #DADADA;
    border: 1.5px solid white;
}

.react-grid-container::-webkit-scrollbar {
    border-radius: 10px;
    width: 6px;
    height: 6px;
    background-color: #DADADA;
}

.react-grid-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #141414;
}


.grid-buttons {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    /* Asettaa napit oikeaan reunaan */
    align-self: flex-end;
    /* Asettaa samalle tasolle kuin grid */
    max-width: 95vw;
    /* Sama leveys kuin react-grid-container */
}

.grid-buttons-btm {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.header-text {
    text-align: center;
}

.footer-text {
    text-align: center;
}

.clickable-header {
    cursor: pointer;
}



.grid-element-name {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.grid-element-name .name-left {
    background-color: #EFEFEF;
    height: 100%;
    width: 15px;
}

.grid-element-name .name-right {
    flex: 2;
    margin-left: 5px;
}

.grid-element-name .name-right.prefix {
    color: rgba(67, 67, 67, 0.512);
}





.element-hole-table {
    /*  background-color: red; */
}

.element-hole-table table {
    border-collapse: separate;
    border: solid #E7E7E7 1px;
    border-radius: 6px;
}

.element-hole-table td,
.element-hole-table th {
    border-left: solid #E7E7E7 1px;
    border-top: solid #E7E7E7 1px;
}

.element-hole-table th {
    background-color: #F9F9F9;
    border-top: none;
}

.element-hole-table td.disabled {
    background-color: #F9F9F9;
}

.element-hole-table td:first-child,
.element-hole-table th:first-child {
    border-left: none;
}



/* Tekstikenttä */
.element-hole-table input {
    border: none;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.element-hole-table input:focus {
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
.element-hole-table input::-webkit-outer-spin-button,
.element-hole-table input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.element-hole-table input[type=number] {
    -moz-appearance: textfield;
}

/* Valintakenttä */
.element-hole-table select {
    border: none;
}

.element-hole-table select:focus {
    outline: none;
}



.element-hole-table tfoot {
    border-top: none !important;
}

.element-hole-table tfoot tr,
.element-hole-table tfoot td {
    border: none !important;
    background: transparent !important;
}

.excel-settings-inputs {
    display: flex;
    flex-direction: column;
}

.excel-settings-default-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5em;
}

.excel-settings-default-input .label {
    margin: 0;
    flex: 0.8;
}

.excel-settings-default-input .input {
    flex: 1;
}



#formula-draggable-modal-mask {
    background-color: #7474743f;
}

.formula-draggable-modal {
    padding: 10px;
}

.formula-draggable-modal-input {
    background-color: #F9F9F9;
    border-color: #E7E7E7;
}

.formula-draggable-modal-input:hover,
.formula-draggable-modal-input:focus-within {
    background-color: #F9F9F9;
    border-color: #969696;
}


.add-material-modal-item {
    /*     width: 100%; */
    display: flex;
    flex-direction: row;
    margin-top: 1em;

}

.add-material-modal-item .name {
    margin: 0;
}


.add-material-modal-itemized {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
}

.add-material-modal-itemized .itemized-text {
    margin: 0;
    margin-left: 0.5em;
    font-size: 12px;
    color: #BABABA;
    transition: color 0.3s ease; /* Lisää tämä rivi */
}

.add-material-modal-itemized .itemized-text.checked {
    color: #000000;
}