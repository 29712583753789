.projectmain {
    /*   border: 4px dashed #244CDB; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
}



/* Project page content */
.projectpage-content {
    width: 1200px;
    transition: margin-right 0.3s ease-in-out, margin-left 0.3s ease-in-out, width 0.1s ease-in-out;
    padding-bottom: 1em;
}


@media (max-width: 1500px) {
    .projectpage-content {
        width: 80vw;
    }
}

@media (max-width: 1000px) {
    .projectpage-content {
        width: 90vw;
    }
}

/* Käsittely kun etappien/kuromien hallinta auki */
.projectpage-content.open {
    margin-right: 2em;
}

@media (max-width: 2000px) {
    .projectpage-content.open {
        width: 65vw;
        margin-left: -15%;
    }
}




/*
.projectpage-content.closing {
    margin-right:8vw;
}

.projectpage-content.closed {
    margin-right: auto;
}

 */



.project-loading-view {
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    padding: 1vw;
}

.oneLine {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


.back-arrow {
    font-size: 1.2em;
    margin-bottom: 7px;
}

/* DroppableContainer */
.droppablecontainer {
    position: relative;
}

.droppablecontainer.open {
    display: block;
    /* Näytetään kun open-luokka on aktiivinen */
    animation: bounce-in 0.5s ease-in;
}

.droppablecontainer.closing {
    animation: bounce-out 0.5s ease-in;
    display: none;
    /* Piilotetaan animaation jälkeen */
}

@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: translateX(400px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes bounce-out {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(400px);
    }
}

.droppablecontainer-placeholder {
    margin-top: 10vh;
    align-self: center;
    position: fixed;
    /*  border: 4px dashed blue;   */
}


.droppablecontainerFixed {
    /*   margin-top: 10vh; */
    align-self: center;
    height: 80vh;
    position: fixed;
    /*  width: 25vh; */
    width: 22.5vw;
    max-width: 400px;
    /*    max-width: 400px; */
    /*  border: 4px dashed blue;   */
}

.droppablecontainerInner {
    height: 83vh;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
}



.droppablecontainerInner::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #DADADA;
    border: 1.5px solid white;
    /* Use your background color instead of White */
}

.droppablecontainerInner::-webkit-scrollbar {
    border-radius: 10px;
    width: 6px;
    background-color: #DADADA;
}

.droppablecontainerInner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #141414;
}


.project-header-smallrow {
    display: flex;
    flex-direction: row;
    margin-bottom: -0.3em;
}

.project-header-id {
    font-size: calc(1em + 0.2vw);
    margin: 0;
    text-align: left;
    color: #FB554C;
}

.project-header-schedule {
    font-size: calc(1em + 0.2vw);
    margin-top: 0;
    text-align: left;
    color: #000000;
    margin-bottom: -0.3em;
    margin-left: auto;
}

.project-header {
    font-size: calc(2.2em + 0.4vw);
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
}

/* Tilattu/Valmistettu/Toimitettu */
.project-header-headeritems {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    /*     border: 1px solid red;
 */
}

.project-header-headeritems .item {
    margin-right: 3vw;
    /* width: 7vw; */
    /*     border: 1px solid #2B4BF2;
 */
}

.project-header-headeritems .label {
    /*  font-size: 20px; */
    font-size: calc(0.8em + 0.2vw);
    /* border: 1px solid red; */
    margin-bottom: 0;
    color: #000;
}

.project-header-headeritems .value {
    /* font-size: 30px; */
    font-size: calc(1.4em + 0.6vw);

    /* border: 1px solid red; */
    margin-top: -2px;
    margin-bottom: 0;
}


.project-header-headeritems .items-right {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.screen-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FAFAFA;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 27px;
}

.screen-button {
    cursor: pointer;
    /*     border: 1px solid red; */
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-radius: 8px;
}

.screen-button.selected {
    background-color: #E1E1E1;
}





.project-divider {
    border-color: red;
}


.project-box {
    border-radius: 32px;
    margin-top: 30px;
    padding-top: 3vw;
    padding-bottom: 3vw;
    padding: 1vw;
}


@media (min-width: 500px) {
    .project-box {
        background-color: #FAFAFA;
    }
}


@media (max-width: 500px) {
    .project-box {
        padding: 0.5vw;
    }
}



.project-box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}

.project-box-name {
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.project-box-label {
    font-size: 12px;
    margin: 0px;
}


.project-box-right {
    display: flex;
    flex-direction: row;
    margin-left: auto;
}



.dividerTest {
    margin-top: 10px;

}

/* Data map item */
.project-p-item {
    padding: 10px;
    border-top: 1px solid rgba(80, 96, 108, 0.09);
    border-bottom: 1px solid rgba(80, 96, 108, 0.09);
    /*     border: 1px solid red */
}

@media (max-width: 500px) {
    .project-p-item {
        padding-left: 0;
    }
}






.project-p-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
}


.project-p-header .item-title {
    flex: 3;
    max-width: 30vw;
    overflow: hidden;
}

@media (max-width: 500px) {
    .project-p-header .item-title {
        max-width: unset;
    }
}


.project-p-header .name {
    font-size: 20px;
    margin: 0;
    /*     border: 1px solid blue; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.project-p-header .item-footer {
    display: flex;
    flex-direction: row;
}

.project-p-header .factory {
    /*   width: 5em; */
    margin: 0;
    margin-right: 1em;
}

.project-p-header .item-numbers {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.project-p-header .number {
    margin: 0;
    flex: 1;
    display: flex;
    align-items: center;
}


/* Mobiili numerot */


/* Toimitusstyles */






/* .project-p-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
}


.project-p-header .item-title {
    flex: 3;
    max-width: 30vw;
    overflow: hidden;
}

@media (max-width: 500px) {
    .project-p-header .item-title {
        max-width: unset;
    }
}


.project-p-header .name {
    font-size: 20px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
 */



/* Data map item */
/* .project-p-item {
    padding: 10px;
    border-top: 1px solid rgba(80, 96, 108, 0.09);
    border-bottom: 1px solid rgba(80, 96, 108, 0.09);
}

@media (max-width: 500px) {
    .project-p-item {
        padding-left: 0;
    }
}
 */



.project-delivery-item {
    padding: 10px;
    border-top: 1px solid rgba(80, 96, 108, 0.09);
    border-bottom: 1px solid rgba(80, 96, 108, 0.09);
    display: flex;
    flex-direction: row;
    /*     border: 1px solid red; */
    align-items: center;
}

.project-delivery-circle {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    margin-left: 0em;
    margin-right: 1em;
}

/* Vasen puoli */
.project-delivery-left {
    display: flex;
    flex-direction: row;
    border: 1px solid red;
    align-items: center;

}

.project-delivery-item .item-left {
    color: #484848;
}

.project-delivery-item .item-left .kpl {
    margin-left: 1em;
}

.project-delivery-item .item-left .weight {
    margin-left: 1em;
}

.project-delivery-item .item-left .label {
    margin-left: 0.2em;
}

.project-delivery-item .item-left .vehicle {
    margin-left: 0.8em;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #FFF;
    background-color: #b2b2b2;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 5px;
}

.project-delivery-item .item-left .schedule {
    margin-left: 0.8em;
}

.project-delivery-item .item-left .date,
.project-delivery-item .item-left .hour {
    margin-left: 0.4em;
}

/* Oikea puoli */
.project-delivery-item .item-right {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
}

.project-delivery-item .item-right .label {
    font-size: 12px;
}

.project-delivery-item .item-right .date,
.project-delivery-item .item-right .hour {
    width: 100px;
    /* Aseta tähän sopiva leveys */
    text-align: center;
    /* Varmistaa tekstin kohdistuksen */
    white-space: nowrap;
    /* Estää tekstin siirtymisen riville */
    overflow: hidden;
    /* Piilottaa ylimääräisen tekstin */
    margin-left: 0.7em;
    font-size: 19px;
}

.project-delivery-item .item-right .hour {
    width: 60px;
    margin-left: 0.4em;
}

.project-delivery-item .item-right .date.delivered,
.project-delivery-item .item-right .hour.delivered {
    color: #B3B3B3;
}


.project-delivery-item .item-right .item-pdf {
    margin-left: 1em;
    display: flex;
    align-items: center;
}



/* Web numerot */
.project-p-header .item-labelvalue {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item-labelvalue .label {
    margin-right: 0.6em;
    /*     font-size: 20px; */
    font-size: 0.9em;

    /* border: 1px solid red; */
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
}

.item-labelvalue .value {
    margin-right: 0.4em;

    /*     font-size: 30px; */
    font-size: 1.4em;

    /* border: 1px solid red; */
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
}


.project-p-header .item-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.project-p-header .item-arrow .item-arrow-icon {
    font-size: 1.0em;
}

.project-p-header .item-pdf {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}




.project-p-table p {
    display: flex;
    margin: 0;
    /*  width: 5vw; */
    /* border: 1px solid red; */
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: left;
}


/* @media (max-width: 500px) {
    .project-p-table p {
        width: unset;
        flex: 1;
    }
} */

.project-p-table-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5vw;
    border-bottom: 1px solid rgba(80, 96, 108, 0.09);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    cursor: pointer;
}

.project-p-table-values {
    padding: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid rgba(80, 96, 108, 0.09);
    border-bottom: 1px solid rgba(80, 96, 108, 0.09);
}

.project-p-table-values:hover {
    background-color: #f1f1f1;
}


.inBan {
    color: #c90000;
}

.inBanIcon {
    margin-right: 5px;
    margin-left: -15px;
    width: 10px;
}


.inList {
    background-color: #e6e6e6;
    border-radius: 8px;
    padding-left: 8px;
    margin-left: -8px;
}

.inList:hover {
    background-color: #ededed;
}

.table-first {
    /* border: 1px solid red; */
    /*     background-color: red;
    border-radius: 10px; */
    justify-content: flex-start !important;
}




.listTrue {
    color: rgba(20, 220, 64, 1) !important;
}

.listHalf {
    color: rgba(205, 175, 20, 1) !important;
}

.listFalse {
    color: #9b9b9b !important;
}

.project-p-table-footers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid rgba(80, 96, 108, 0.09);
    margin-bottom: 1.5rem;
}


.draggableRowHandle {
    display: contents;
}

.notList-left {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding-left: 8px;
    margin-left: -8px !important;
}

.notList-right {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}


.dragEnabled {
    background-color: #ffffff;
}


.dragDelivered {
    background-color: #c5c5c5;
    color: #0000007c;
    border-radius: 8px;
    padding-left: 8px;
    margin-left: -8px;
}

.dragDelivered:hover {
    background-color: #c5c5c5;
}

.draggable {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}





/* Mikäli etappeja ei ole */
.droppablegroup-placeholder {
    margin: 20px;
    padding: 20px 10px 20px 10px;
    border-radius: 10px;
    width: 13vw;
    border: 3px solid #ededed;
    background-color: #FAFAFA;
}




/* Normi etappi */
.droppablegroup {
    /*  margin: 20px 5px 20px 0px; */
    margin-bottom: 20px;
    margin-right: 5px;
    padding: 20px 10px 20px 10px;
    border-radius: 10px;
    /*    width: 13vw; */
    border: 3px dashed #244CDB;
}

.droppablegroup:first-child {}

.droppablegroup:last-child {
    margin-top: 20px;
    margin-bottom: 0px;
}



.droppablegroup.true {
    border: 3px dashed #244CDB;
}

.droppablegroup.false {
    border: 3px solid #244CDB;
}




.droppablebox {
    padding: 5px;
    padding-left: 15px;
    /* border-radius: 10px; */
    /* width: 20vw; */
    /* border: 3px dashed #0badad; */
}

.droppablebox.parent {
    padding-left: 5px;
    padding-right: 10px;
}

.droppablebox p {
    margin-bottom: 0;
}

.droppablebox-header {
    display: flex;
    margin-top: -10px;
}

.droppablebox-header-delivery {
    /*     display: flex;
 */
    margin-top: -10px;
}

.droppablebox-header-title {
    display: flex;
    flex-direction: row;
    font-size: 1.4em;
    margin: 0;
    align-items: center;
}

.droppablebox-header-date {
    font-size: 0.9em;
    margin: 0;
    margin-top: -5px;
}

.droppablebox-header-delivery-vehicle {
    margin-left: auto;
    font-size: 12px;
    background-color: #949494;
    padding: 3px 8px 3px 8px;
    color: #fff;
    border-radius: 10px;
}

.droppablebox-header-delivery-vehicle.overWeight {
    background-color: red;
}


.droppablebox-header-delivery-2row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.droppablebox-header-delivery-2row-right {
    margin-left: auto;
    font-size: 12px;
}

.droppablebox-header-delivery-factory {
    font-size: 12px;
}

.droppablebox-header-delivery-width {
    font-size: 12px;
    margin-left: 0.5em;
}

.droppablebox-header-delivery-kpl {
    font-size: 12px;
    margin-left: 0.5em;
}

.droppablebox-header-delivery-weight {
    font-size: 12px;
    margin-left: 0.5em;
}

.droppablebox-header-delivery-weight.overWeight {
    color: red;
}


.droppablebox-placeholder-header-title {
    font-size: 1.4em;
    margin: 0;
    color: #5B5B5B;
}

.droppablebox-placeholder-text {
    font-size: 1em;
    margin: 0;
    color: #5B5B5B;
    margin-top: 1em;
}



.droppablebox-multidate {
    margin-left: auto;
    margin-right: 1rem;
}

.droppablebox-edit {
    margin-left: auto;
}

.droppablebox-delete {
    margin-left: 20px;
}

.droppablegroup-icon {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.205);
}

.droppablegroup-icon.rotate {
    transform: rotate(-45deg);

}


.droppablegroup-icon:hover {
    color: #000;
}



.droppableBack {
    border-radius: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}




.draggableItemDelivery {
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background: #4A15E1;
    border-radius: 8px;
    color: #fff;
    /* Ylös, vase, alas, oikee */
    padding: 3px 10px 3px 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.draggableItemDelivery.notConstruced {
    padding-right: 10px;
    background: #E11515;
}





.draggableItemDeliveryOrder {
    width: 3ch;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.draggableItemDeliveryText {
    font-size: 12px;
    margin: 0;
    text-align: left;
    white-space: nowrap;
}

.draggableItemDeliveryText2 {
    font-size: 12px;
    margin: 0;
    text-align: left;
    margin-left: 1em;
}

.draggableItemDeliveryTextWeight {
    flex: 1;
    font-size: 12px;
    margin: 0;
    text-align: left;
    white-space: nowrap;
}



.draggableItem {
    margin: 5px;
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    background: #4A15E1;
    border-radius: 32px;
    color: #fff;
    /* Ylös, vase, alas, oikee */
    padding: 3px 10px 3px 10px;
    align-items: center;
}


.draggableItemText {
    flex: 1;
    font-size: 12px;
    margin: 0;
    text-align: left;
    white-space: nowrap;
    /*     overflow: hidden;
    text-overflow: ellipsis; */

}


.draggableItemText2 {
    flex: 1;
    font-size: 12px;
    margin: 0;
    text-align: left;
    margin-left: 10px;
}

.draggableItemClose {
    cursor: pointer;
    transform: rotate(-45deg);
}



.draggableCustomRow {
    display: flex;
    flex-direction: row;
    background: #4A15E1;
    border-radius: 32px;
    color: #fff;
    /* Ylös, vase, alas, oikee */
    padding: 3px 10px 3px 10px;
    width: 140px !important;
}

.draggableCustomRow-1 {
    margin: 0;
}

.draggableCustomRow-2 {
    margin: 0;
    margin-left: auto;
}


.draggableRowText {
    margin: 0;
    text-align: center;
}

.draggableDatePicker {
    border-radius: 8px;
    margin-left: auto;
    margin-right: 10px;
    background-color: transparent !important;
    color: white;
    border: unset
}

.draggableDatePicker input {
    color: white !important;
    text-align: center;
}

.draggableDatePicker.red input {
    color: rgb(255, 50, 50) !important;
    text-align: center;
}

.draggableDatePicker .ant-picker-clear {
    color: rgb(255 255 255) !important;
    background: #4A15E1 !important;
}

.ant-picker-suffix {
    color: rgb(255 255 255) !important;
}



.etappiAddbox {
    /* border: 4px dashed purple !important; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



.etappiAdd-input {
    width: 50%;
}

.etappiBtns {
    display: flex;
    flex-direction: row;
    margin-top: 0.7em;
    margin-left: 1em;
}


/* MODAL */

.workmodalBase {
    flex: 1;
    justify-content: 'center';
    align-items: "center";
}

.proj-modalContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: 'red';
    display: flex;
    justify-content: center;
    align-items: center;
}

.proj-modalContainer .label {
    margin: 0;
    margin-top: 1em;
}

.proj-modal {
    background-color: #fff;
    padding: 1.6rem;
    border-radius: 15px;

    backdrop-filter: blur(5px);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);

    min-width: 400px;
}



.proj-modalHeaderRow {
    display: flex;
    flex-direction: row;
}

.proj-modalHeaderClose {
    margin-left: auto;
    display: flex;
    align-self: center;
    font-size: 17px;
}

.proj-modalHeader {
    font-size: 20px;
    margin: 0;
}

.proj-modalHeaderUser {
    font-size: 15px;
    margin: 0;
    margin-top: -5px;
    margin-bottom: 1em;
}



.proj-saveButtonCont {
    margin-top: 20px;
    width: 100%;
}

.proj-saveButton {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 10px;

    font-size: 17px;

    background: #2BF263;
    border-radius: 8px;
    cursor: pointer;

    border: 0;

    width: 100%;
}

.proj-saveButton:hover {
    background: #28e35d;
}

.proj-saveButton:active {
    background: #29ea60;
}

.proj-cancelButtonCont {
    margin-top: 20px;
    width: 100%;
}

.proj-cancelButton {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 10px;

    font-size: 17px;

    background: #ef0000;
    border-radius: 8px;
    cursor: pointer;

    border: 0;

    width: 100%;
}

.proj-cancelButton:hover {
    background: #c90000;
}

.proj-cancelButton:active {
    background: #ef0000;
}

.rangepicker {
    width: 100%;
    height: 40px;
    margin-left: auto;
}

.rangepicker .ant-picker-clear {
    color: rgb(0 0 0) !important;
    background: #ffffff !important;
}

.etappiRow {
    display: flex;
    flex-direction: row;
}

.etappiArrowDiv {
    margin-left: auto;
    margin-right: 1vw;
}

.etappiArrow {
    font-size: large;
    margin-top: 5px;
}



/* Info */
.project-info {
    background-color: #FAFAFA;
    border-radius: 32px;
    padding: 24px;
    margin-top: 30px;
}

.project-info-loading {
    margin-top: 10vh;
    margin-bottom: 10vh;
    display: flex;
    justify-content: center;
    padding: 1vw;
}

.project-info-company {
    font-size: 0.9em;
    margin: 0;
    color: #949494;
    margin-left: 20px;
}

.project-info-title {
    font-size: 1.5em;
    margin: 0;
    margin-bottom: 1em;
    /*     margin-left: 20px; */
}


.project-info-box {
    display: flex;
    flex-direction: row;
}

.project-info-box-header {
    font-size: 1em;
    margin: 0;
}

.project-info-edit {
    cursor: pointer;
    color: #CCCCCC;
}

.project-info-marginleft {
    margin-left: auto;
}

.project-info-row {
    display: flex;
    flex-direction: row;
}


.project-info-contacts {
    background-color: #FFFFFF;
    border-radius: 14px;
    padding: 24px;
    margin-right: 1em;
    flex: 1;
    position: relative;
}

.project-info-contacts-item {
    margin-top: 1em;
}

.project-info-contacts-item p {
    margin: 0;
}

.project-info-contacts-add {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  
  .project-info-contacts-addIcon {
    font-size: 20px;
    color: #fff;
  }
  

.project-info-box2-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 4;
}

.project-info-delivery {
    background-color: #FFFFFF;
    border-radius: 14px;
    padding: 24px;
    flex: 3;
}

.project-info-delivery-item {
    margin-top: 10px;
    margin-right: 4em;
    flex: 1;
}

.project-info-delivery-item-header {
    margin: 0;
    margin-right: 10px;
}

.project-info-delivery-item-value {
    margin: 0;
}

.project-info-delivery-map {
    margin-top: 1em;
    height: 250px;
    /*     border: 1px solid gray; */
    border-radius: 14px;
    overflow: hidden;
}


.project-info-delivery-map-btn {
    padding: 6px;
    background-color: #000;
    color: #fff;
    border-radius: 20px;
    padding-left: 2em;
    padding-right: 2em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    border: unset;
}


.project-info-delivery-map-btn-icon {
    margin-right: 0.5em;
}


.project-info-schedule {
    margin-top: 1em;
    background-color: #FFFFFF;
    border-radius: 14px;
    padding: 24px;
    flex: 1;
}

.project-info-schedule-value {
    margin: 0;
    margin-top: 4px;
}

.project-info-schedule-edit {
    cursor: pointer;
    margin-left: auto;
    color: #CCCCCC;
}


.project-modal-label {
    margin: 0;
    margin-top: 1em;
}