
.quotation-header-label {
    background-color: #0C2FFA;
    margin: 0;
    border-radius: 20px;
    color: #fff; 
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 1em;
    display: flex;
    align-items: center;
}

.quotation-header-end-item {
    margin-top: 1em;
}

.quotation-header-end-item .label {
    margin: 0;
}

.quotation-header-end-item .value {
    margin: 0;
    font-size: calc(1.5em + 0.4vw);
}

/* Info */
.quotation-info {
    background-color: #FAFAFA;
    border-radius: 32px;
    padding: 24px;
    margin-top: 2em;
}

.quotation-info-title {
    font-size: 1.5em;
    margin: 0;
    margin-bottom: 1em;
    margin-left: 20px;
}






.quotation-info-box {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 14px;
    padding: 24px;
}

.quotation-info-box-row {
    display: flex;
    flex-direction: row;
}


.quotation-info-box-add {
    margin-top: auto;    
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 35px;
    height: 35px;
    cursor: pointer;
}


.quotation-info-box-addIcon {
    font-size: 20px;
    color: #fff;
}



.quotation-info-box-header {
    font-size: 1em;
    margin: 0;
}

.quotation-info-edit {
    cursor: pointer;
    color: #CCCCCC;
}

.quotation-info-marginleft {
    margin-left: auto;
}

.quotation-info-row {
    display: flex;
    flex-direction: row;
}


.quotation-info-contents {
    background-color: #FFFFFF;
    border-radius: 14px;
    padding: 24px;
    margin-right: 1em;
    flex: 1;
}

.quotation-info-contents-item {
    margin-top: 1em;
}

.quotation-info-contents-item p {
    margin: 0;
}

.quotation-info-row-item {
    margin-top: 10px;
    margin-right: 4em;
    flex: 1;
}

.quotation-info-row-item-header {
    margin: 0;
    margin-right: 10px;
}

.quotation-info-row-item-value {
    margin: 0;
}

.quotation-info-row-map {
    margin-top: 1em;
    height: 250px;
    /*     border: 1px solid gray; */
    border-radius: 14px;
    overflow: hidden;
}


.quotation-info-row-map-btn {
    padding: 6px;
    background-color: #000;
    color: #fff;
    border-radius: 20px;
    padding-left: 2em;
    padding-right: 2em;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
}


.quotation-info-row-map-btn-icon {
    margin-right: 0.5em;
}


.quotation-calculation-item {
    background-color: #FFF;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-radius: 14px;
    margin-bottom: 0.7em;
    cursor: pointer;
    text-decoration: none;
 }


.quotation-calculation-item .label {
    margin: 0;
    text-decoration: none;
    color: #000;
    
}


.quotation-info-box-calc-header {
    margin: 0;
    font-size: 20px;
}


.quotation-calculation-elementgroup {
    background-color: #dddddd;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-radius: 14px;
    margin-bottom: 0.7em;
    cursor: pointer;
    text-decoration: none;
 }

.quotation-calculation-elementgroup .label {
    margin: 0;
    text-decoration: none;
    color: #000;
    
}




/* Eleemnttiryhmän oma laskenta */
.quotation-group-calculation {
    background-color: #FAFAFA;
    border-radius: 12px;
    padding: 20px;
    margin-top: 2em;
    border: 1px solid #E7E7E7
}

.quotation-group-calculation .title-row {
    display: flex;
    flex-direction: row;
}

.quotation-group-calculation .title-row .title {
    font-size: 1.3em;
    margin: 0;
}

.quotation-group-calculation .title-row .text2 {
    font-size: 0.9em;
    margin: 0;
}


.quotation-group-calculation .title-row .totals{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0.7;
    margin-left: auto;
}
