.contact-box {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    margin-top: 1em;
    position: relative;
}


.contact-box-title-row {
    /*     border: 1px solid red; */
    display: flex;
    flex-direction: row;
}


.contact-box-title {
    margin: 0;
    font-size: 22px;
    margin-bottom: 0.8em;
}

.contact-box-title-edit {
    margin-left: auto;
    color: #CCC
}


.contact-box-row {
    /*     border: 1px solid red; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contact-box-infos-left {
    /*     border: 1px solid blue; */
    flex: 1;
}


.contact-box-infos-right {
    /*     border: 1px solid yellow; */
    flex: 1;
}




.contact-box-info {
    display: flex;
    flex-direction: row;
    margin-top: 0.4em;
}

.contact-box-info-label {
    margin: 0;
    flex-basis: 120px; /* Leveys labelille */
}

.contact-box-info-label2 {
    margin: 0;
    margin-right: 2em;
}

.contact-box-info-value {
    margin: 0;
    flex-grow: 1; /* Ottaa loput tilasta */
}

@media (max-width: 768px) {
    .contact-box-info {
      flex-direction: column;
    }
  
    .contact-box-info-label {
      flex-basis: auto;
      margin-bottom: 5px;
    }
  }



  .contact-box-add {
    margin-top: auto;    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  
  .contact-box-addIcon {
    font-size: 20px;
    color: #fff;
  }
  












  .contact-project-item {
   display: flex;
   flex-direction: row;
   margin-bottom: 0.7em;
   text-decoration: none;
   color: #000;
   cursor: pointer;

  }

  .contact-project-text {
    margin: 0;
    
  }

  .contact-project-text:hover{
    color: #1890ff;
    transition: 0.3s;

  }


  .contact-projects-map {
    margin-top: 1em;
    height: 250px;
/*     border: 1px solid gray; */
    border-radius: 14px;
    overflow: hidden;
}